.box {
  /* background-image: linear-gradient(to right, #cc2b5e, #753a88); */
  background-color: #ed1c24;
  padding: 1px;
  width: calc(100% - 15px);
  margin-bottom: 2px;
}

img {
  width: 100%;
}

.container {
  overflow-x: hidden !important;
}

@media (max-width: 768px) {
  #box2 {
    margin-top: 40px;
  }

  #service-img {
    height: 240px !important;
    width: 100% !important;
  }
}
