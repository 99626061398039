.header-image {
  width: 100%;
  height: 750px !important;
  background-size: cover !important;
}

.header-container {
  position: relative;
  text-align: center;
  color: white;
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: 700;
  font-size: 50px;
  text-shadow: 2px 2px 3px #000;
}

.badge {
  min-width: 100px;
  font-size: 15px;
}

@media (max-width: 768px) {
  .header-text {
    font-size: 2rem !important;
    font-weight: 700;
  }
  .header-image {
    height: 340px !important;
  }

  .badge {
    min-width: 100px !important;
    font-size: 12px !important;
  }

  .Card {
    margin: 10px 0;
  }
}

@media (min-width: 1440px) {
  .header-image {
    width: 100%;
    height: 1200px !important;
  }
}
