@font-face {
  font-family: "Caviar Dreams";
  src: url("https://fonts.googleapis.com/css2?family=Caviar+Dreams&display=swap");
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Caviar Dreams", sans-serif !important;
  margin: 0;
  padding: 0;
  --webkit-user-select: none;
  scroll-behavior: smooth;
/*   --moz-user-select: none;
  user-select: none; */
}
.blue-text {
  color: #181344 !important;
}
.red-text {
  color: #ed1c24 !important;
}

.red-text-shadow {
  text-shadow: 1px 1px 2px #ed1c24 !important;
}

.blue-text-shadow {
  text-shadow: 1px 1px 2px #181344 !important;
}

.gray-text-shadow {
  text-shadow: 1px 1px 2px #606060 !important;
}

.white-text-shadow {
  text-shadow: 1px 1px 2px #fff !important;
}

.black-text-shadow {
  text-shadow: 2px 2px 3px #000 !important;
}

.gray-text {
  color: #606060 !important;
}
.red-shadow {
  box-shadow: 5px 5px 10px #ed1c24;
}
.gray-shadow {
  box-shadow: 5px 5px 10px #606060;
}

.black-shadow {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.white-shadow {
  box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.5);
}

.blue-shadow {
  box-shadow: 5px 5px 10px #181344;
}

#nav-btn:hover {
  background-color: #181344;
}

#nav-btn {
  color: white;
  transition: background-color 0.5s ease-in, color 0.5s ease;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}

#preloader {
  background-image: url("./assets/preloaders/preloader.png");
  height: 100vh;
  width: 100%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px;
  overflow: hidden;
  position: fixed;
}

.rotating-image {
  animation: rotate 2s linear infinite;
}

/*transition */
.fade {
  animation: transitionIn ease-out 1.5s forwards;
}

/* Aimation */
@keyframes transitionIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none !important;
}
.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  /* border: 2px 2px 4px #fff !important ; */
  border: none !important;
}

.btn:focus {
  box-shadow: none !important;
}

@media (max-width: 768px) {
  #preloader {
    background-size: 100px;
  }
}

@media (min-width: 1440px) {
  h1 {
    font-size: 69px !important;
  }

  h2 {
    font-size: 58px !important;
  }

  a,
  h5,
  .accordion-body > li,
  .accordion-body,
  p,
  .lead {
    font-size: 1.5em !important;
  }

  button {
    font-size: 1.5rem !important;
  }
}
