.slide-image {
  width: 100%;
  height: 700px !important;
  transition: transform 4s ease;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
}
.carousel-item.active .slide-image {
  transform: scale(1.1);
}

.caption {
  opacity: 0;
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: fit-content;
  font-size: 700;
  transform: translate(-50%, 50%);
  text-align: right;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  margin: 0;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2), 0px 4px 20px rgba(0, 0, 0, 0.3);
  animation: floatAndZoom 5s forwards;
  display: inline-block;
  max-width: 40%;
}

@keyframes floatAndZoom {
  0% {
    opacity: 0;
    transform: translate(-50%, 50%) scale(0.8);
  }
  100% {
    transform: translate(70%, -50%) scale(1);
    opacity: 1;
  }
}

.carousel-item.active .slide-image {
  transform: scale(1.1);
}
.text-shadow {
  text-shadow: 4px 4px 3px rgba(0, 0, 0, 0.5) !;
}

/* Responsive styles */
@media (min-width: 1440px) {
  .slide-image {
    height: 1200px !important;
  }
  .caption {
    max-width: 100% !important;
  }

  .caption h1 {
    font-size: 150px;
  }
  .caption {
    max-width: 50%;
  }

  .caption p {
    font-size: 40px;
  }

  @keyframes floatAndZoom {
    0% {
      opacity: 0;
      transform: translate(-50%, 50%) scale(0.8);
    }
    100% {
      transform: translate(100%, -70%) scale(1);
      opacity: 1;
    }
  }
}

@media (max-width: 1440px) {
  .caption {
    max-width: 50%;
    margin: 0 auto;
  }
  .caption h1 {
    font-size: 60px;
  }
  @keyframes floatAndZoom {
    0% {
      opacity: 0;
      transform: translate(-50%, 50%) scale(0.8);
    }
    100% {
      transform: translate(30%, -70%) scale(1);
      opacity: 1;
    }
  }
}
@media (max-width: 768px) {
  .caption h1 {
    font-size: 28px !important;
  }
  .caption {
    max-width: 70%;
  }

  .caption p {
    font-size: 14 px !important;
  }

  @keyframes floatAndZoom {
    0% {
      opacity: 0;
      transform: translateY(20%) scale(0.8);
    }
    100% {
      opacity: 1;
      transform: translateY(-30%) scale(1);
    }
  }

  .w-100 {
    height: 500px !important;
  }

  .slide-image {
    width: 100%;
    height: 500px !important;
    transition: transform 4s ease;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  }
}

@media (max-width: 576px) {
  .caption {
    max-width: 100%;
  }
  .caption h1 {
    font-size: 40px;
    font-weight: "700";
  }

  .caption p {
    font-size: 13px;
    font-weight: "700";
    width: 100%;
  }
  .slide-image {
    width: 100%;
    height: 420px !important;
  }
}
