.custom-button {
  /* ED1C24 */
  /* 181344 */
  background-color: #181344;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: color 0.4s;
}

.custom-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  transition: left 0.4s;
}

.custom-button:hover::before {
  left: 100%;
}

.custom-button:hover {
  background-color: #ed1c24;
  transition: 0.5s all ease;
}

/* media queries */

@media (max-width: 1400px) {
  #claim_wrapper {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
  }
}

@media (max-width: 1000px) {
  #claim_wrapper {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
}
