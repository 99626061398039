.controls {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
}

.control-button {
  background-color: #e3e9ee;
  border: none;
  padding: 10px 15px;
  border-radius: 60%;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.control-button:hover {
  background-color: #e2e6ea;
  transform: scale(1.1);
}

.control-button i {
  font-size: 18px;
  color: #495057;
}