/* navbar.css */

/* General Styles */
.nav-link {
  color: #181344 !important;
}

.nav-link:hover,
.nav-link:focus {
  color: #ed1c24 !important;
}

.navbar-brand img {
  height: 40px !important;
}

/* Dropdown menu styling */
.dropdown-menu {
  background-color: #f8f9fa;
}

.dropdown-item {
  color: #181344 !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #ed1c24 !important;
}

.dropdown-toggle::after {
  display: none !important;
}

@media (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
  }

  .dropdown-menu {
    position: absolute !important;
    left: 0 !important;
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
}

.navbar {
  padding: 25px !important;
}

.navbar > .container-fluid {
  padding-left: 30px;
}

.navbar-brand img {
  width: 150px;
}

.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

@media (max-width: 768px) {
  .navbar > .container-fluid {
    padding-left: 10px;
    padding-right: 5px;
  }

  .navbar-nav .dropdown-menu {
    width: 175px !important;
  }
}

@media (min-width: 1440px) {
  a {
    font-size: 1.5em !important;
  }

  .navbar-brand img {
    width: 250px;
    height: 80px !important;
  }
}
